import Cookie from "js-cookie";
import axios from "axios";
import router from "@/router";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

axios.defaults.headers.common["Accept-Language"] =
  localStorage.getItem("lang") || "en";

// API for backend is always same as domain (window.location.hostname), unless its localhost, develop or staging envirement
let beckendAPI;
let domainProtocol = location.protocol;
if (
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("dev.") ||
  window.location.hostname.includes("staging.") ||
  window.location.hostname.includes("madetight.io") ||
  window.location.hostname.includes("cloudfront.net")
) {
  beckendAPI = process.env.VUE_APP_API_URL;
} else {
  beckendAPI = domainProtocol + "//" + window.location.hostname + "/api/";
}

let request = axios.create({
  baseURL: beckendAPI,
  headers: axios.defaults.headers.common,
  credentials: true,
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error?.response.status === 401 &&
      process.env.VUE_APP_WHITELABEL === "VCGMarkets"
    ) {
      console.log("error", error?.response);
      const url = process.env.VUE_APP_API_URL.includes("dev.")
        ? "https://dev-website.anttix.app/en/auth/login"
        : "https://vcgmarkets.com/en/auth/login";

      //   redirect to external login
      Promise.reject(error?.response);
      return window.open(url, "_self");
    }
    // Do something with response error
    if (error?.response.status === 401) {
      Cookie.remove("auth_token");
      Cookie.remove("type");
      Cookie.remove("x-tracking-id");
      Cookie.remove("reg-token");
      Cookie.remove("state");
      Cookie.remove("demoLink");
      localStorage.removeItem("step");
      localStorage.removeItem("storedData");
      router.push("/auth/login");
      window.location.reload();
    }
    return Promise.reject(error?.response);
  }
);

request.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (
    !(
      process.env.VUE_APP_WHITELABEL === "TD365" ||
      process.env.VUE_APP_WHITELABEL === "TDSouthAfrica" ||
      process.env.VUE_APP_WHITELABEL === "Blackstone" ||
      process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
      process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
      process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
      process.env.VUE_APP_WHITELABEL === "TradiNext" ||
      process.env.VUE_APP_WHITELABEL === "OrbitInvest" ||
      process.env.VUE_APP_WHITELABEL === "TradeCoreUK" ||
      process.env.VUE_APP_WHITELABEL === "VCGMarkets" ||
      process.env.VUE_APP_WHITELABEL === "TradiNext" ||
      process.env.VUE_APP_WHITELABEL === "PolarisMarkets" ||
      process.env.VUE_APP_WHITELABEL === "Dzengi"
    )
  ) {
    config.headers["whitelabel"] = process.env.VUE_APP_WHITELABEL;
  }
  config.headers["Accept-Language"] = localStorage.getItem("lang") || "en";
  if (Cookie.get("x-tracking-id")) {
    config.headers["x-tracking-id"] = Cookie.get("x-tracking-id");
  }
  return config;
});

export function platform_url({ commit }, trading_account_id) {
  return new Promise((resolve, reject) => {
    request({
      url: `/tpi/accounts/${trading_account_id}/platform_url/`,
      method: "get",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_MOBILE_LOGIN_LINK", resp.data);
        resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  });
}

/**
 * Login
 *
 * @param commit
 * @param dispatch
 * @param user
 * @returns {*}
 */

// LOGIN / LOGOUT

export function loginMobile({ commit }, user) {
  return new Promise((resolve, reject) => {
    commit("AUTH_REQUEST");
    request({
      method: "post",
      data: user,
      url: "accounts/login/",
    })
      .then((resp) => {
        localStorage.removeItem("storedData");
        const token = resp.data.token;
        const userData = resp.data.info;
        const type = resp.data.info.profile.type;
        // const SSO_token = resp.data.info.trading_accounts[0].PlatformUrl.slice(resp.data.info.trading_accounts[0].PlatformUrl.indexOf('=') + 1);
        // commit('SET_SSO_TOKEN', SSO_token)
        Cookie.set("auth_token", token);
        Cookie.set("type", type);
        commit("AUTH_SUCCESS", [userData, token]);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        localStorage.clear();
        reject(err);
      });
  });
}

export function login({ commit }, user) {
  return new Promise((resolve, reject) => {
    commit("AUTH_REQUEST");
    request({
      method: "post",
      data: user,
      url: "accounts/login/",
    })
      .then((resp) => {
        localStorage.removeItem("storedData");
        const token = resp.data.token;
        const userData = resp.data.info;
        const type = resp.data.info.profile.type;
        Cookie.set("auth_token", token);
        Cookie.set("type", type);
        commit("AUTH_SUCCESS", [userData, token]);

        commit("SET_ICM_ACADEMY_MODAL", true);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        localStorage.clear();
        reject(err);
      });
  });
}

export function logout({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "accounts/logout/",
      method: "get",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    });
    Cookie.remove("auth_token");
    Cookie.remove("type");
    Cookie.remove("state");
    Cookie.remove("x-tracking-id");
    Cookie.remove("demoLink");
    Cookie.remove("reg-token");
    localStorage.removeItem("step");
    localStorage.removeItem("storedData");
    commit("LOGOUT");
    delete request.defaults.headers["Authorization"];
    commit("SET_ICM_ZENDESK_VERSION", "");
    resolve();
  });
}

// RESTORE PASSWORD

export function restore_password({ commit }, user) {
  return new Promise((resolve, reject) => {
    commit("AUTH_REQUEST");
    request({
      method: "post",
      data: user,
      url: "accounts/forgot-password/request/",
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// SIGNUP DEMO

export function set_demo_signup_data({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "signup/demo/",
      method: "OPTIONS",
    }).then((resp) => {
      commit("SET_SIGNUP_DATA", resp.data);
      resolve();
    });
  }).catch((err) => {
    reject(err);
  });
}

export function set_demo_signup_data_with_whitelabel({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "signup/demo/",
      method: "OPTIONS",
      headers: {
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    }).then((resp) => {
      commit("SET_SIGNUP_DATA", resp.data);
      resolve();
    });
  }).catch((err) => {
    reject(err);
  });
}

export function send_demo_signup_data({ commit }, payload) {
  let args = new URLSearchParams(document.location.search);
  const cxd = args.get("cxd") || "";
  const affid = args.get("affid") || "";

  return new Promise((resolve, reject) => {
    let id = "";
    let token = "";

    let url = "/signup/demo/";
    if (cxd || affid) {
      url = `/signup/demo/?cxd=${cxd}&affid=${affid}`;
    }

    request({
      url,
      method: "post",
      data: payload,
    })
      .then(async (resp) => {
        id = resp.data.id;

        token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        commit("SET_SIGNUP_DATA", resp.data);

        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function send_demo_signup_data_with_whitelabel({ commit }, payload) {
  let args = new URLSearchParams(document.location.search);
  const cxd = args.get("cxd") || "";
  const affid = args.get("affid") || "";

  return new Promise((resolve, reject) => {
    let headers = {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };

    if (cxd !== "") {
      document.cookie = `cxd=${cxd}; SameSite=None; Secure`;
    }
    if (affid !== "") {
      console.log("AFFID", affid);
      console.log("AFF_ID", affid);
      document.cookie = `aff_id=${affid}; SameSite=None; Secure`;
      document.cookie = `affid=${affid}; SameSite=None; Secure`;
      headers = {
        whitelabel: process.env.VUE_APP_WHITELABEL,
        Cookie: document.cookie,
      };
    }

    request({
      url: "/signup/demo/",
      method: "post",
      data: payload,
      headers,
    })
      .then((resp) => {
        const token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        commit("SET_SIGNUP_DATA", resp.data);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// SIGNUP DEMO COMPANY

export function set_demo_company_signup_data({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "signup/demo/?client_type=company",
      method: "OPTIONS",
    }).then((resp) => {
      commit("SET_SIGNUP_DATA", resp.data);
      resolve();
    });
  }).catch((err) => {
    reject(err);
  });
}

export function send_demo_company_signup_data({ commit }, payload) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/demo/?client_type=company",
      method: "post",
      data: payload,
    })
      .then((resp) => {
        const token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        commit("SET_SIGNUP_DATA", resp.data);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// OPEN LIVE ACCOUNT
export function send_signup_data_open_live_account_step_two(
  { commit },
  payload
) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "put",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: payload,
    })
      .then((resp) => {
        console.log("PUT 1");
        commit("RESET_STEP_NUMBER");
        commit("SET_SUITABLE_STATUS", resp.data.suitable);
        resolve(resp.data.suitable);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function send_signup_data_open_live_account_step_one(
  { commit },
  payload
) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: payload.method,
      data: payload.data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        console.log("PUT 2");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function set_signup_data_open_live_account({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "OPTIONS",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    }).then((resp) => {
      console.log("PUT 3");
      const removedEmptyFields = resp.data.step_meta.filter(
        (s) => s.length !== 0
      );
      resp.data.step_meta = removedEmptyFields;

      commit("SET_SIGNUP_DATA", resp.data);
      resolve();
    });
  });
}

export function set_signup_data_open_live_account_with_whitelabel({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "OPTIONS",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    }).then((resp) => {
      console.log("PUT 4");
      commit("SET_SIGNUP_DATA", resp.data);
      resolve();
    });
  });
}

// LIVE REGISTER

export function set_signup_data({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "OPTIONS",
    }).then((resp) => {
      // console.log("PUT 5", resp.data);
      commit("SET_SIGNUP_DATA", resp.data);
      resolve();
    });
  });
}

export function set_signup_data_with_whitelabel({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "OPTIONS",
      headers: {
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    }).then((resp) => {
      console.log("PUT 6");
      commit("SET_SIGNUP_DATA", resp.data);
      resolve();
    });
  });
}

export function send_signup_data_step_one_with_whitelabel({ commit }, payload) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: payload.method,
      data: payload.data,
      headers: {
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    })
      .then((resp) => {
        console.log("PUT 7", resp);
        const token = resp.headers["x-registration-id"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("reg-token", resp.headers["x-registration-id"]);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function send_signup_data_step_one({ commit }, payload) {
  return new Promise((resolve, reject) => {
    console.log("PUT 8 PAYLOAD", payload);
    delete payload.data.password_confirmation;

    request({
      url: "/signup/live/",
      method: payload.method,
      data: payload.data,
    })
      .then((resp) => {
        const token = resp.headers["x-registration-id"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("reg-token", resp.headers["x-registration-id"]);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function send_signup_data_step_two({ commit }, payload) {
  delete payload.password_confirmation;
  console.log("PUT 9 PAYLOAD", payload);
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "put",
      headers: {
        "x-registration-id": Cookie.get("reg-token"),
      },
      data: payload,
    })
      .then((resp) => {
        const token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        if (
          !(
            process.env.VUE_APP_WHITELABEL === "TD365" &&
            resp.data.suitable.result === false
          )
        ) {
          commit("RESET_STEP_NUMBER");
        }
        commit("SET_SUITABLE_STATUS", resp.data.suitable);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function send_signup_data_step_two_with_whitelabel({ commit }, payload) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "put",
      headers: {
        "x-registration-id": Cookie.get("reg-token"),
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
      data: payload,
    })
      .then((resp) => {
        console.log("PUT 10");
        const token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        if (
          !(
            process.env.VUE_APP_WHITELABEL === "TD365" &&
            resp.data.suitable.result === false
          )
        ) {
          commit("RESET_STEP_NUMBER");
        }
        commit("SET_SUITABLE_STATUS", resp.data.suitable);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function send_signup_data_step_two_without_reg_id({ commit }, payload) {
  return new Promise((resolve, reject) => {
    request({
      url: "/signup/live/",
      method: "put",
      data: payload,
      headers: {
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    })
      .then((resp) => {
        console.log("PUT 11");
        const token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        commit("RESET_STEP_NUMBER");
        commit("SET_SUITABLE_STATUS", resp.data.suitable);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// GET TRADING ACCOUNTS AND NEXT STEP FOR ALL REGISTER FORMS

export function display_trading_platforms({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/tpi/backends/?tracking_id=live",
      method: "get",
    }).then((resp) => {
      commit("SET_TRADING_PLATFORMS", resp.data);
      resolve();
    });
  });
}

export function display_trading_platforms_with_whitelabel({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/tpi/backends/?tracking_id=live",
      method: "get",
      headers: {
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    }).then((resp) => {
      commit("SET_TRADING_PLATFORMS", resp.data);
      resolve();
    });
  });
}

export function display_demo_trading_platforms({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/tpi/backends/?tracking_id=demo",
      method: "get",
    }).then((resp) => {
      commit("SET_DEMO_TRADING_PLATFORMS", resp.data);
      resolve();
    });
  });
}

export function display_demo_trading_platforms_with_whitelabel({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "/tpi/backends/?tracking_id=demo",
      method: "get",
      headers: {
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    }).then((resp) => {
      commit("SET_DEMO_TRADING_PLATFORMS", resp.data);
      resolve();
    });
  });
}

export function display_trading_platforms_tracking_links({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: `/tpi/backends/?tracking_id=${Cookie.get("x-tracking-id")}`,
      method: "get",
    }).then((resp) => {
      commit("SET_TRADING_PLATFORMS", resp.data);
      resolve();
    });
  });
}

export function upgrade_trading_platforms({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: `/tpi/backends/?target=upgrade`,
      method: "get",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    }).then((resp) => {
      // ICM insisted that the trading account with backend.type mt4 are showed before mt5 then cTrader
      // so we needed to reorder the array of objects that we get from the BE
      function reorderObjectsByBackendType(arr) {
        const order = { MT4: 1, MT5: 2, CTrader: 3 };
        arr.sort((a, b) => {
          const typeA = a.backend && a.backend.type;
          const typeB = b.backend && b.backend.type;

          if (!typeA && !typeB) return 0;
          if (!typeA) return 1;
          if (!typeB) return -1;

          const orderA = order[typeA] || Number.MAX_SAFE_INTEGER;
          const orderB = order[typeB] || Number.MAX_SAFE_INTEGER;

          // Custom comparison logic to sort "mt4" before "mt5" before "ctrader"
          if (orderA !== orderB) {
            return orderA - orderB;
          }
          // If the order is the same, you can add additional comparison logic here
          // For example, if you want to further sort objects with the same "type," you can do so.
          return 0;
        });
        return arr;
      }

      const reorderedArray = reorderObjectsByBackendType(resp.data);

      commit("SET_TRADING_PLATFORMS", reorderedArray);
      resolve();
    });
  });
}

export function show_next_step({ commit }) {
  const step = parseInt(localStorage.getItem("step")) + 1;
  localStorage.setItem("step", step);
  commit("SHOW_NEXT_STEP", step);
}

export function reset_step({ commit }) {
  localStorage.setItem("step", "0");
  commit("RESET_STEP");
}
//  USER DATA

export function user_data({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "accounts/user_data/",
      method: "get",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_USER_DATA", resp.data);
        resolve();
      })
      .catch((error) => {
        reject();
      });
  });
}

export function update_user_data({ commit }, payload) {
  return new Promise((resolve, reject) => {
    request({
      url: "accounts/profile/",
      method: "PATCH",
      data: payload,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function profile_options({ commit }, payload) {
  return new Promise((resolve, reject) => {
    request({
      url: "accounts/profile/",
      method: "OPTIONS",
      data: payload,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve();
        commit("SET_PROFILE_OPTIONS", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function change_password({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "accounts/change_password/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function reset_password({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: `accounts/forgot-password/reset/${data.token}/?format=json`,
      data: data.data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function reset_trading_account_password({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: `/tpi/accounts/${data.account_id}/reset_password/`,
      data: data.data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
        "content-type": "application/json;charset=UTF-8",
        whitelabel: process.env.VUE_APP_WHITELABEL,
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// ACCOUNT SUMMARY

export function add_account({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      url: "tpi/accounts/?fetch_balance=true",
      // url: "tpi/accounts/",
      method: "post",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: data,
    })
      .then((resp) => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function get_backends({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "tpi/backends/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      method: "get",
    }).then((resp) => {
      const demoAccounts = resp.data.filter(
        (account) => account.backend.mode === "Demo"
      );
      const liveAccounts = resp.data.filter(
        (account) => account.backend.mode === "Live"
      );

      /**
       * This function removes duplicates from an array of objects based
       * on the `backend.type` property. In case of multiple objects
       * with the same `backend.type`, the last occurrence is kept.
       *
       * @param {Array} arr - The array to remove duplicates from.
       * @returns {Array} - An array with duplicate `backend.type` removed.
       */
      const removeDuplicatesByBackendType = (arr) => {
        // Using reduce to create an object where keys are `backend.type`
        // and values are the last object of that type found in the array.
        const lastOfType = arr.reduce((acc, obj) => {
          acc[obj.backend.type] = obj;
          return acc;
        }, {});

        // Getting the values of the `lastOfType` object to create
        // an array of unique objects based on `backend.type`.
        return Object.values(lastOfType);
      };
      const uniqueArray = removeDuplicatesByBackendType(liveAccounts);

      // ICM insisted that the trading account with backend.type mt4 are showed before mt5 then cTrader
      // so we needed to reorder the array of objects that we get from the BE
      function reorderObjectsByBackendType(arr) {
        const order = { MT4: 1, MT5: 2, CTrader: 3 };
        arr.sort((a, b) => {
          const typeA = a.backend && a.backend.type;
          const typeB = b.backend && b.backend.type;

          if (!typeA && !typeB) return 0;
          if (!typeA) return 1;
          if (!typeB) return -1;

          const orderA = order[typeA] || Number.MAX_SAFE_INTEGER;
          const orderB = order[typeB] || Number.MAX_SAFE_INTEGER;

          // Custom comparison logic to sort "mt4" before "mt5" before "ctrader"
          if (orderA !== orderB) {
            return orderA - orderB;
          }
          // If the order is the same, you can add additional comparison logic here
          // For example, if you want to further sort objects with the same "type," you can do so.
          return 0;
        });
        return arr;
      }

      const reorderedArray = reorderObjectsByBackendType(uniqueArray);

      commit("SET_DEMO_BACKEND_ACCOUNTS", demoAccounts);
      commit("SET_LIVE_BACKEND_ACCOUNTS", reorderedArray);
      resolve();
    });
  });
}

export function get_system_currencies({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      url: "tpi/system_currencies/",
      method: "get",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    }).then((resp) => {
      commit("SET_SYSTEM_CURRENCIES", resp.data);
      resolve();
    });
  });
}

export function account_data({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "tpi/accounts/?fetch_balance=true",
      // url: "tpi/accounts/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_ACCOUNT_DATA", resp.data);
        const live_accounts = resp.data.filter(
          (account) => account.backend.mode === "Live"
        );
        commit("SET_LIVE_ACCOUNT_DATA", live_accounts);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function change_account_password({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "patch",
      url: `tpi/accounts/${data[1]}/`,
      data: data[0],
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function reset_account_password({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: `tpi/accounts/${data[1]}/forgot_password/`,
      data: data[0],
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Legal documents - revoke consent

export function revoke({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "accounts/consents/revoke/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function consents({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "accounts/consents/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function profile({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "accounts/profile/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_PROFILE", resp.data);
        if (
          process.env.VUE_APP_WHITELABEL === "Blackstone" ||
          process.env.VUE_APP_WHITELABEL === "TD365" ||
          process.env.VUE_APP_WHITELABEL === "TDSouthAfrica"
        ) {
          commit("SET_ANNUAL_CHECK", resp.data.annual_check);
        }

        const cookies = document.cookie.split("; ");
        // Loop through the cookies and check if any match the given cookieName
        for (const cookie of cookies) {
          const [name, value] = cookie.split("=");
          if (name === "state") {
            if (Cookie.get("state") === "Waitlisted") {
              if (Cookie.get("state") !== resp.data.state) {
                commit("SET_STATUS_CHANGE_MESSAGE_MODAL", true);
              }
            }
          }
        }

        Cookie.set("state", resp.data.state);
        Cookie.set("type", resp.data.profile.type);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// PAYMENT HISTORY

export function get_payment_history({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "tpi/history/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_PAYMENT_HISTORY", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

// TRANSACTION HISTORY

export function get_transaction_history({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "tpi/transaction_history/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_TRANSACTION_HISTORY", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

// UPLOAD DOCUMENTS

export function set_upload_presets({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "accounts/documents/presets/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_UPLOAD_PRESETS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function upload_document({ commit }, payload) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "accounts/documents/",
      data: payload,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundary2dtwAkjIThYBAMmB",
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function set_uploaded_documents({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "accounts/documents/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_UPLOADED_DOCUMENTS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function delete_document({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "delete",
      url: `accounts/documents/${data}/`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

// Depostit funds

export function deposit_methods({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `payments/deposit_methods/${data}/`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_DEPOSIT_METHODS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function make_deposit({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "payments/deposit/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function make_deposit_no_payment_method({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `accounts/deposit/?amount=${data.amount}&gateway=${data.override_gateway}&trading_account=${data.trading_account}`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function make_deposit_no_amount({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `accounts/deposit/?gateway=${data.override_gateway}&trading_account=${data.trading_account}`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function bank_payment_instructions({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "GET",
      baseURL: beckendAPI.replace("api/", "") + `${data}`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function payments_gateway({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "accounts/payment_gateways/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_PAYMENTS_GATEWAY", resp.data);
        commit("SET_ALERT_NAV", false);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        commit("SET_ALERT_NAV", true);
        commit(
          "SET_ALERT_NAV_MESSAGE",

          err?.data.detail ?? "You should give your consent to all documents"
        );
        reject(err);
      });
  });
}

export function request_bank_instructions({ commit }) {
  return new Promise((resolve, reject) => {
    let headers = {
      "content-type": "application/json",
      "Accept-Language": localStorage.getItem("lang") || "en",
      Authorization: "token " + Cookie.get("auth_token"),
    };

    if (process.env.VUE_APP_WHITELABEL !== "VCGMarkets") {
      headers.whitelabel = process.env.VUE_APP_WHITELABEL;
    }

    axios({
      baseURL:
        beckendAPI.replace("api/", "") +
        "payments/bank/request_bank_instructions/",
      headers: headers,
      method: "options",
    })
      .then((resp) => {
        // we are removing the form filed with key backend
        // since we dont want user to enter that manually
        let formFields = resp.data.actions.POST.fields;
        formFields = formFields.filter(function (obj) {
          return obj.key !== "backend";
        });
        resp.data.actions.POST.fields = formFields;
        commit("SET_BANK_ACCOUNT_FORMS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

export function set_bank_instructions({ commit }, data) {
  return new Promise((resolve, reject) => {
    let headers = {
      "content-type": "application/json",
      Authorization: "token " + Cookie.get("auth_token"),
      "Accept-Language": localStorage.getItem("lang") || "en",
    };

    if (process.env.VUE_APP_WHITELABEL !== "VCGMarkets") {
      headers.whitelabel = process.env.VUE_APP_WHITELABEL;
    }

    axios({
      baseURL:
        beckendAPI.replace("api/", "") +
        "payments/bank/request_bank_instructions/",
      headers: headers,
      method: "post",
      data: data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        commit("AUTH_ERROR", err);
        reject(err);
      });
  });
}

// Internal transfer

export function internal_transfer({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "accounts/internal_transfer/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Analysis

export function analysis_url({ commit }, lang) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `env/trading_central_url/?lang=${lang}`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Bank accounts

export function bank_account_forms({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "OPTIONS",
      url: "accounts/bank_accounts/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_BANK_ACCOUNT_FORMS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function bank_accounts({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "accounts/bank_accounts/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_BANK_ACCOUNTS", resp.data.results);
        commit("SET_ALERT_NAV", false);
        resolve(resp);
      })
      .catch((err) => {
        commit("SET_ALERT_NAV", true);
        commit(
          "SET_ALERT_NAV_MESSAGE",
          err?.data?.detail ?? "You should give your consent to all documents"
        );
        reject(err);
      });
  });
}

export function add_bank_account({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "accounts/bank_accounts/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function delete_bank_account({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "delete",
      url: `accounts/bank_accounts/${data}/`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function edit_bank_account({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "patch",
      url: `accounts/bank_accounts/${data.id}/`,
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Withdraw funds

export function withdrawal_methods({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "/payments/withdrawal_methods/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_WITHDRAWAL_METHODS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function withdraw({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/payments/withdraw/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function withdraw_request_td365({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/accounts/withdraw_request/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function withdraw_praxispay({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/payments/withdraw/iframe/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function available_cards({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url:
        beckendAPI.replace("api/", "") +
        `/payments/gatetopay/cards/?trading_account=${data}`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_AVAILABLE_CARDS", resp.data.results);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Apply for MasterCard

export function card_requests({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "options",
      url: "/card_requests/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_CARD_REQUESTS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apply_for_mastercard({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/card_requests/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Annual check

export function annual_check({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "accounts/profile/annual_check/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        // const token = resp.headers['x-auth-token']
        // commit('AUTH_SUCCESS', [resp.data, token])
        // Cookie.set('auth_token', token)
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// IB PORTAL

// Register

export function countries({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "accounts/countries/",
    })
      .then((resp) => {
        commit("SET_COUNTRIES", resp.data);
        resolve(resp);
        return resp.data;
      })
      .catch((err) => {
        reject(err);
      });
  }).then(async (res) => {
    const counitres = res.data;
    const url = "https://ipapi.co/json/";
    const getUserCountry = async () => {
      const response = await fetch(url);
      const data = await response.json();
      const code = data?.country_code ? data.country_code : "";
      const findCountry = counitres.find(
        (country) => country.iso_code === code
      );

      commit("SET_USER_COUNTRY", findCountry);
    };
    await getUserCountry();
  });
}

// Individual

export function ib_forms_personal_1({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "ib/signup/personal/1/",
      data: data,
    })
      .then((resp) => {
        const token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function ib_forms_personal_2({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "ib/signup/personal/2/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Company

export function ib_forms_company_1({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "ib/signup/company/1/",
      data: data,
    })
      .then((resp) => {
        const token = resp.headers["x-auth-token"];
        commit("AUTH_SUCCESS", [resp.data, token]);
        Cookie.set("auth_token", token);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function ib_forms_company_2({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "ib/signup/company/2/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Ib profile

export function ib_profile({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "ib/profile_data/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_IB_PROFILE", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function update_ib_user_data({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "ib/profile_data/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Summary

export function summary({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "ib/summary/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_IB_SUMMARY", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Tracking Links

export function tracking_links({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `ib/tracking_links/?limit=${data.limit}&offset=${data.offset}`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_TRACKING_LINKS", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function tracking_links_previous({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_TRACKING_LINKS", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function add_tracking_links({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/ib/tracking_links/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function tracking_links_options({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "options",
      url: "/ib/tracking_links/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit(
          "SET_TRACKING_LINKS_WHITELABEL",
          resp.data.actions.GET.fields[14].choices
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Client list

export function client_list({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `ib/list_users/?page_size=${data.page_size}&page=${data.page}&ordering=id`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_CLIENT_LIST", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function client_list_previous({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_CLIENT_LIST", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sub_ib_client_list({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `ib/list_ib_users/?page_size=${data.page_size}&page=${data.page}&ordering=id`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_SUB_IB_CLIENT_LIST", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sub_ib_client_list_previous({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_SUB_IB_CLIENT_LIST", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sub_ib_list({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `ib/list_ib/?page_size=${data.page_size}&page=${data.page}&ordering=id`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_SUB_IB_LIST", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sub_ib_list_previous({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_SUB_IB_LIST", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Upload documents

export function set_ib_upload_documents({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "ib/upload_documents/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        resolve(resp);
        commit("SET_IB_UPLOAD_DOCUMENTS", resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function ib_upload_documents({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "ib/upload_documents/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundary2dtwAkjIThYBAMmB",
      },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Ib payment history

export function set_ib_payment_history({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `ib/payments/payment-history/?limit=${data.limit}&offset=${data.offset}`,
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_IB_PAYMENT_HISTORY", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function set_ib_payment_history_previous({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: data,
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_IB_PAYMENT_HISTORY", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Request a payment

export function set_payments_info({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "/ib/payments/payment-info/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_PAYMENTS_INFO", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function set_ib_withdrawals_methods({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "/ib/payments/withdrawal_methods/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_IB_WITHDRAWALS_METHODS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function post_withdraw_request({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/ib/payments/withdraw-requests/",
      data: data,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_WITHDRAW_REQUESTS", resp.data.results);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Withdraw requests

export function withdraw_request({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: "/ib/payments/withdraw-requests/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        commit("SET_WITHDRAW_REQUESTS", resp.data.results);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// LEAD FORMS

export function set_lead_forms({ commit }) {
  return new Promise((resolve, reject) => {
    request({
      method: "options",
      url: "/signup/lead/",
    })
      .then((resp) => {
        commit("SET_LEAD_FORMS", resp.data);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function lead_forms({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/signup/lead/",
      data: data,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// tracking links

export function ib_tracking_link_visited() {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: `/ib/link_visited/${Cookie.get("x-tracking-id")}/`,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Demo portal generate SSO token

// export function generate_sso_token({commit}, userEmail) {
//     return new Promise((resolve, reject) => {
//         request({
//             url: `https://beta.dx.trade/dxweb/rest/generateToken?login=${userEmail}&domain=default`,
//             headers: {
//                 'Authorization': 'token ' + Cookie.get('auth_token')
//             }
//         })
//             .then(resp => {
//                 commit('SET_SSO_TOKEN', resp.data)
//                 resolve(resp)
//             })
//             .catch(err => {
//                 commit('AUTH_ERROR', err)
//                 reject(err)
//             })
//     })
// }

export function generate_sso_token({ commit }, trading_account_id) {
  return new Promise((resolve, reject) => {
    request({
      url: `/tpi/accounts/${trading_account_id}/platform_url/`,
      method: "get",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((resp) => {
        const SSO_token = resp.data.slice(resp.data.indexOf("=") + 1);
        commit("SET_SSO_TOKEN", SSO_token);
        resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  });
}

// Brokeree

export function brokeree_token({ commit }, MTexternalID) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: `https://social.main.pres.brokeree.dev/api/auth/generate`,
      headers: {
        role: "Client",
        username: MTexternalID,
        serverId: 2,
        generationKey: "Bj_z9Sx4voBz3Oisa38yLVdEgGaId4KLDVvDxKdnKJY",
      },
    })
      .then((resp) => {
        const widget_token = resp;
        commit("SET_BROKEREE_WIDGET_TOKEN", widget_token);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getBalanceForAccount(id) {
  return new Promise((resolve, reject) => {
    request({
      method: "get",
      url: `/accounts/get_collateral/${id}`,
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// websdk
export function websdk_token({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/kyc/sumsub/websdk-link/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
      data: {
        lang: "en",
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  })
    .then((res) => {
      window.open(res?.data.redirect_url, "_blank");
    })
    .catch((error) => {
      console.error(error);
    });
}

export function websdk_new_token({ commit }, data) {
  return new Promise((resolve, reject) => {
    request({
      method: "post",
      url: "/kyc/sumsub/websdk-token/",
      headers: {
        Authorization: "token " + Cookie.get("auth_token"),
      },
    })
      .then((response) => {
        const token = response.data.token;
        resolve(token);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function set_score_modal({ commit }, data) {
  commit("SET_SCORE_MODAL", data);
}
